import React from 'react';
import { motion } from 'framer-motion';
import { FaPuzzlePiece, FaRocket } from 'react-icons/fa';

const NoCodeImplementations = () => {
  const platforms = [
    { name: 'Salesforce', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png' },
    { name: 'Microsoft Dynamics 365', logo: 'https://1000logos.net/wp-content/uploads/2021/12/Dynamics-365-logo.png' },
    { name: 'Shopify', logo: 'https://logosandtypes.com/wp-content/uploads/2020/11/Shopify.png' },
    { name: 'BigCommerce', logo: 'https://w7.pngwing.com/pngs/399/831/png-transparent-bigcommerce-hd-logo.png' },
    { name: 'WooCommerce', logo: 'https://woocommerce.com/wp-content/uploads/2023/08/woocommerce-01.png' },
  ];

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
              No Code Implementations
            </span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Connect JaanchAI seamlessly with your existing platforms and business systems. Our no-code solution ensures you receive clear, actionable recommendations quickly, maximizing your return on investment.
          </p>
        </motion.div>

        <motion.div 
          className="flex justify-center items-center mb-16"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-1 rounded-full">
            <div className="bg-gray-900 rounded-full p-6">
              <FaPuzzlePiece className="text-7xl text-blue-400" />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-center mb-12"
        >
          <h3 className="text-3xl font-semibold mb-8 text-white">Integrated Platforms</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
            {platforms.map((platform, index) => (
              <motion.div
                key={platform.name}
                className="bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <img src={platform.logo} alt={platform.name} className="h-16 mb-4 mx-auto object-contain" />
                <span className="text-gray-300 font-medium">{platform.name}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <a 
            href="#integrate-now" 
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300"
          >
            Start Integrating Now
            <FaRocket className="ml-2" />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default NoCodeImplementations;