import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FaChartLine, FaBoxes, FaPercentage, FaRobot, FaBrain } from 'react-icons/fa';

const solutions = [
  { title: "Sales AI", icon: FaChartLine, color: "#3B82F6" },
  { title: "Inventory AI", icon: FaBoxes, color: "#10B981" },
  { title: "Pricing AI", icon: FaPercentage, color: "#8B5CF6" },
  { title: "Automation AI", icon: FaRobot, color: "#EF4444" },
  { title: "Forecast AI", icon: FaBrain, color: "#F59E0B" },
];

const HeroBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % solutions.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <section className="bg-black text-white min-h-screen flex items-center justify-center relative overflow-hidden pt-20"> {/* Added pt-20 for top padding */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={containerVariants}
          className="text-center"
        >
          <motion.h1 
            className="text-6xl sm:text-7xl font-extrabold mb-6 leading-tight"
            variants={itemVariants}
          >
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
              JaanchAI
            </span>
            <br />
            Redefining Business Intelligence
          </motion.h1>
          <motion.p 
            className="text-xl mb-12 text-gray-300 max-w-2xl mx-auto"
            variants={itemVariants}
          >
            Harness the power of next-gen AI to revolutionize your decision-making process and skyrocket your business growth.
          </motion.p>
          <motion.div 
            className="flex justify-center space-x-4 mb-16"
            variants={itemVariants}
          >
            <a
              href="#get-started"
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105"
            >
              Get Started
            </a>
            <a
              href="#demo"
              className="bg-transparent border-2 border-purple-500 text-purple-500 py-3 px-8 rounded-full text-lg font-semibold hover:bg-purple-500 hover:text-white transition-all duration-300 transform hover:scale-105"
            >
              Watch Demo
            </a>
          </motion.div>
        </motion.div>
        
        <motion.div 
          className="relative h-96"
          variants={itemVariants}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-72 h-72 bg-gradient-to-br from-blue-500 to-purple-600 rounded-full filter blur-3xl opacity-20 animate-pulse"></div>
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            {solutions.map((solution, index) => (
              <motion.div
                key={solution.title}
                className={`absolute transition-all duration-500 ${
                  index === activeIndex ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
                }`}
                style={{
                  transform: `rotate(${(index - activeIndex) * 72}deg) translateY(-120px)`,
                }}
              >
                <solution.icon className="text-6xl" style={{ color: solution.color }} />
              </motion.div>
            ))}
            <div className="text-center">
              <motion.h3
                className="text-2xl font-bold mb-2"
                key={activeIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {solutions[activeIndex].title}
              </motion.h3>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroBanner;