import React, { useEffect, useState } from 'react';
import { FaChartLine, FaUsers, FaPercentage, FaDollarSign, FaBoxes, FaLink, FaArrowRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const JaanchAIOverview = () => {
  const [activeCase, setActiveCase] = useState(0);

  const connectedSystems = [
    { name: "ERP", description: "Enterprise Resource Planning for comprehensive business process management." },
    { name: "Point of Sale", description: "Systems to capture real-time sales data." },
    { name: "E-commerce Platforms", description: "Shopify, Magento for online sales tracking." },
    { name: "Marketplaces", description: "Amazon, eBay for broader market reach and sales integration." },
    { name: "SFTP", description: "Secure File Transfer Protocol for secure data exchange." },
    { name: "Google Analytics", description: "For detailed web and marketing analytics." },
  ];

  const caseStudies = [
    {
      icon: <FaChartLine className="text-4xl text-blue-400" />,
      title: "Boosting Electronics Sales",
      details: [
        { label: "Issue", text: "20% decline in high-end electronics sales" },
        { label: "Action Plan", text: "Limited-time discount on 15 premium products" },
        { label: "Expected Outcome", text: "12% increase in sales within next quarter" },
      ],
    },
    {
      icon: <FaUsers className="text-4xl text-green-400" />,
      title: "Reducing High-Value Customer Churn",
      details: [
        { label: "Risk", text: "9,500 high-value customers likely to churn in 60 days" },
        { label: "Potential Loss", text: "$3 million annual revenue loss if not addressed" },
        { label: "Action Plan", text: "Personalized retention campaigns and loyalty programs" },
      ],
    },
    {
      icon: <FaPercentage className="text-4xl text-purple-400" />,
      title: "Maximizing Seasonal Promotions",
      details: [
        { label: "Opportunity", text: "35 seasonal products with high promotional potential" },
        { label: "Action Plan", text: "Strategic discounts and enhanced marketing efforts" },
        { label: "Expected Outcome", text: "18% increase in conversion rates" },
      ],
    },
    {
      icon: <FaDollarSign className="text-4xl text-yellow-400" />,
      title: "Strategic Price Increases",
      details: [
        { label: "Action Needed", text: "Price adjustments on 22 high-demand products" },
        { label: "Strategy", text: "8% price increase based on analysis and forecasting" },
        { label: "Expected Profit", text: "25% increase in quarterly profits" },
      ],
    },
    {
      icon: <FaBoxes className="text-4xl text-red-400" />,
      title: "Inventory Optimization",
      details: [
        { label: "Issue", text: "150 top-selling items at risk of stockout within 4 weeks" },
        { label: "Risk", text: "Potential loss of $75,000 in sales due to stockouts" },
        { label: "Action Plan", text: "Prioritize restocking using predictive analytics" },
        { label: "Expected Outcome", text: "Reduced stockouts and maintained sales momentum" },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCase((prev) => (prev + 1) % caseStudies.length);
    }, 8000);
    return () => clearInterval(interval);
  }, [
    caseStudies.length,
  ]);

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 

          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            JaanchGPT:
          </span>
          <span className="text-white"> Transforming E-commerce with Intelligent Insights</span>
        </motion.h2>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-4">Overview</h3>
          <p className="text-xl text-gray-300">
            <strong>JaanchGPT</strong> is a sophisticated AI-driven platform designed to integrate multiple e-commerce data sources, delivering detailed, actionable insights to optimize sales, pricing, promotions, and inventory management.
          </p>
        </motion.div>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-6">Connected Systems</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {connectedSystems.map((system, index) => (
              <motion.div 
                key={index}
                className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm p-6 rounded-xl"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <FaLink className="text-2xl text-blue-400 mb-2" />
                <h4 className="text-xl font-semibold text-white mb-2">{system.name}</h4>
                <p className="text-gray-300">{system.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-6">Case Study: Driving Growth with JaanchGPT</h3>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-600 rounded-2xl opacity-20 filter blur-3xl animate-pulse"></div>
            <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-xl p-8 rounded-2xl relative z-10">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeCase}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="flex items-center mb-6">
                    {caseStudies[activeCase].icon}
                    <h4 className="text-2xl font-bold text-white ml-4">{caseStudies[activeCase].title}</h4>
                  </div>
                  <ul className="space-y-4">
                    {caseStudies[activeCase].details.map((detail, index) => (
                      <li key={index} className="flex items-start">
                        <span className="text-blue-400 font-semibold mr-2">{detail.label}:</span>
                        <span className="text-gray-300">{detail.text}</span>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-4">Impact</h3>
          <p className="text-xl text-gray-300 mb-8">
            By leveraging JaanchGPT's comprehensive insights, companies can make data-driven decisions to enhance product sales, optimize inventory, and drive overall profitability. The intelligent integration of diverse data sources ensures real-time, actionable insights that align with business goals and customer needs.
          </p>
          <motion.a
            href="#learn-more"
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300 inline-flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More About JaanchGPT
            <FaArrowRight className="ml-2" />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIOverview;